defineDs('DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Core/Model',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (m, Model, KenoApi, KenoInfo, KenoUtils, DataLayer) {

    // Model:
    var KenoGame = Model('KenoGame', function (content) {

      // Constants:
      this.classicMaxRows = m.prop(50);
      this.numbersPerRowMin = m.prop(2);
      this.numbersPerRowMax = m.prop(10);
      this.numbersIntervalMax = m.prop(70);
      this.stakeOptions = m.prop(KenoInfo.getStakeOptions());
      this.numberOfDrawsOptions = m.prop(KenoInfo.getDrawRepeatOptions());
      this.ready = m.prop(m.deferred());
      this.rebuyCouponId = m.prop(content.rebuyCouponId || null);
      this.verticalType = m.prop(content.verticalType);

      // Data:
      this.status = m.prop(content.status || 'open');
      this.playType = m.prop(content.playType || null);
      this.stakePerRow = m.prop(content.stakePerRow || KenoInfo.getStakeDefault()); // Required by PurchaseBar
      this.numberOfDraws = m.prop(typeof content.numberOfDraws === 'undefined' ? KenoInfo.getDrawRepeatDefault() : content.numberOfDraws); // Required by PurchaseBar
      this.numberOfNumbersPerRow = m.prop(content.numberOfNumbersPerRow || 9);
      this.rows = m.prop(content.rows || []);
      this.rowsToGenerate = m.prop(content.rowsToGenerate || 0);
      this.systemLevel = m.prop(content.systemLevel || (this.playType() === 'System' ? 2 : null)); // Required by KenoSystem
      this.startUrl = m.prop(content.startUrl || window.location.href.split('?')[0]);
      this.purchaseTracked = m.prop(content.purchaseTracked || false);
      this.couponId = m.prop(content.couponId || null);
      this.isProductDetailsTracked = m.prop(content.isProductDetailsTracked || false);
      this.isAddToCartTracked = m.prop(content.isAddToCartTracked || false);
      this.isPurchaseCompleteTracked = m.prop(content.isPurchaseCompleteTracked || false);
      this.plusSubscriptionJackpot = m.prop(content.plusSubscriptionJackpot || null);
      this.firstDepositInfo = m.prop(content.firstDepositInfo || null);

      // Functions - For manipulating rows:
      this.rowCount = function () {
        var count = 0;
        var rows = this.rows();
        var amount = rows.length;

        for (var i = 0; i < amount; i++) {
          var row = rows[i];

          if (row.count > count) {
            count = row.count + 1;
          } else {
            count++;
          }
        }

        return count;
      }.bind(this);

      this.sortRow = function (numbers) {
        numbers.sort(function (a, b) {
          return a.number < b.number ? -1 : 1;
        });

        return numbers;
      };

      this.addNumber = function (row, number, autogenerated) {
        row = this.getRow(row);

        row.numbers.push({ number: number, autogenerated: autogenerated || false });
        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.removeNumber = function (row, number) {
        row = this.getRow(row);

        var index = -1;
        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            index = i;

            break;
          }
        }

        if (index > -1) {
          row.numbers.splice(index, 1);
        }

        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.addRow = function (numbers) {
        numbers = numbers || [];

        var count = this.rowCount();

        this.rows().push({ count: count, numbers: numbers, state: 'clean' });

        this.save();
      }.bind(this);

      this.removeRow = function (row) {
        var rows = this.rows();

        rows.splice(row - 1, 1);

        this.rows(rows);

        if (rows.length === 0) {
          this.addRow();
        }

        this.save();
      }.bind(this);

      this.removeAllRows = function () {
        this.rows([]);

        // We need double rows, as we're practically resetting the rows to original state
        this.addRow();
        this.addRow();

        this.save();
      }.bind(this);

      this.resetRow = function (rowNumber) {
        var row = this.getRow(rowNumber);

        row.numbers = [];
        row.state = 'clean';

        this.setRow(rowNumber, row);

        this.save();
      }.bind(this);

      this.setRow = function (row, content) {
        var rows = this.rows();

        rows[row - 1] = content;

        this.rows(rows);

        this.save();
      }.bind(this);

      this.autogenerate = function (rowNumber, rowLength) { // Autogenerates a specific row
        var deferred = m.deferred();
        var row = this.getRow(rowNumber);
        var nextRow = this.getRow(rowNumber + 1);
        var numbers = [];
        var self = this;

        // Prepare list of existing user selected numbers
        for (var i = 0; i < row.numbers.length; i++) {
          var number = row.numbers[i];

          if (!number.autogenerated) {
            numbers.push(number.number);
          }
        }

        KenoApi.getRandomNumbers({ playType: this.playType(), rowsToGenerate: 1, rowLength: rowLength, requiredNumbers: numbers }).then(function (rows) {
          rows = rows[0];

          for (var i = 0; i < rowLength; i++) {
            var generated = rows[i];

            if (numbers.indexOf(generated) === -1) {
              self.addNumber(rowNumber, generated, true);
            }
          }

          if (rowLength > 1 && !nextRow) {
            self.addRow();
          }

          deferred.resolve();
        });

        return deferred.promise;
      }.bind(this);

      this.gameAutogenerate = function () { // Autogenerate a whole set of rows
        var self = this;
        var deferred = m.deferred();

        if (['KenoSmall', 'KenoLarge', 'KenoMillion'].indexOf(this.playType()) == -1) {
          console.error('fastPlayAutogenerate only support KenoSmall, KenoLarge and KenoMillion');

          return;
        }

        // Generate new rows:
        KenoApi.getRandomNumbers({ playType: this.playType() }).then(function (rows) {
          self.rows = m.prop([]);

          for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            var numbers = row.map(function (number) { return { number: number, autogenerated: true }; });

            self.addRow(numbers);
          }

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      // Functions for stake and number of draws:

      this.setNumberOfDraws = function (draws) {
        this.numberOfDraws(draws);

        this.save();
      }.bind(this);

      this.setStakePerRow = function (stake) {
        this.stakePerRow(stake);

        this.save();
      }.bind(this);

      // Functions for KenoSystem:
      this.getSystemString = function () {
        var level = this.systemLevel();
        var row = this.getRow(1);
        var numbers = row && row.numbers ? row.numbers.length : 0;

        if (numbers + 1 < level) {
          return 'invalid';
        }

        return 'K ' + level + '-' + numbers;
      }.bind(this);

      this.getSystemCombinationAmount = function () {
        var row = this.getRow(1);

        if (!this.systemLevel() || !row || row.numbers.length <= this.systemLevel()) {
          return 0;
        }

        var table = {
          2: [3, 6, 10, 15, 21, 28, 36, 45],
          3: [4, 10, 20, 35, 56, 84, 120],
          4: [5, 15, 35, 70, 126, 210],
          5: [6, 21, 56, 126, 252],
          6: [7, 28, 84, 210],
          7: [8, 36, 120],
          8: [9, 45],
          9: [10]
        };

        var combinations = table[this.systemLevel()];
        var index = row.numbers.length - this.systemLevel() - 1;

        return combinations[index];
      }.bind(this);

      // Functions for purchase:

      this.purchase = function (params) {
        params = params || {};

        var self = this;
        var deferred = m.deferred();

        if (this.status() != 'open') {
          deferred.reject();
        } else if (!params.drawId) {
          console.error('No drawId given.');

          deferred.reject({ errorMessage: 'NUMBERGAMES.NO_OPEN_DRAW' });
        } else {
          this.status('pending-confirmation');
          this.save();

          var rows = this.getRowsSummary();
          var rowNumbers = rows.map(function (row) { return { numbers: row }; });

          var options = {
            drawId: params.drawId,
            totalPrice: parseInt(self.totalPrice(), 10),
            multidraw: parseInt(self.numberOfDraws(), 10),
            pricePerRow: parseInt(self.stakePerRow(), 10),
            playType: this.playType() || null,
            rows: rowNumbers,
            salesChannel: (KenoUtils.isMobile() || KenoUtils.isTabletDevice()) ? 'mobile' : 'web'
          };

          // Prize notification - register only enabling
          if (params.notifyPrizeEmail) {
            options.notifyPrizeEmail = true;
          }
          if (params.notifyPrizeSms) {
            options.notifyPrizeSms = true;
          }

          // System
          if (this.playType() === 'KenoSystem') {
            options.system = this.getSystemString();
          }

          // Create request
          var request = KenoApi.createCoupon(options);

          request.then(function (data) {
            self.status('completed');
            self.couponId(data.couponId);

            self.save();

            console.debug('Keno Coupon SUCCESS response', data);

            deferred.resolve(data);
          }, function (data) {
            self.status('open');

            self.save();

            console.debug('Keno Coupon ERROR');

            deferred.reject(data);
          });

        }

        return deferred.promise;
      }.bind(this);

      this.sendToSubscription = function () {
      // Tracking for DataLayer
        this.trackingAddToCart('plus');

        window.location.href = '/plus-abonnement/plus-vaelg-spil?gameType=keno&gameId=' + this.id();
      }.bind(this);

      // Functions - For tracking (DataLayer eCommerce buzzword)

      this.createDataLayerProduct = function (subscription, event) {
      // Product array for datalayer
        var dataLayerProducts = [];

        // Main product
        var product = {};

        if (subscription) {
        // subscription product (PLUS abonnement)
          product.name = 'plus';
          product.price = this.stakePerRow().toString();
          product.brand = 'dlo_plu';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('plus_keno', this.numberOfDraws(), this.playType());
          product.quantity = this.getRowAmount();
          for (var i = 0; i < 7; i++) { // 7 is for the number of days in a week.
            dataLayerProducts.push(product);
          }
        } else {
          product.name = 'keno';
          product.price = DataLayer.rowPrice(this.stakePerRow(), this.numberOfDraws(), this.playType());
          product.brand = 'dlo_ken';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('keno', this.numberOfDraws(), this.playType());
          if (event != 'productDetails') {
            product.quantity = DataLayer.quantity('keno', this.getRowsSummary().length, this.playType(), this.getSystemCombinationAmount());
          }
          dataLayerProducts.push(product);
        }

        return dataLayerProducts;
      };

      this.trackingAddToCart = function () {
      // Check if the event has already been pushed
        if (this.isAddToCartTracked() == false) {
          this.isAddToCartTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          DataLayer.addToCart(this.createDataLayerProduct());
        } else {
          console.warn('addToCart has been pushed already!');
        }
      }.bind(this);

      this.trackingProductDetails = function () {
      // Check if the event has already been pushed
        if (this.isProductDetailsTracked() == false) {
          this.isProductDetailsTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          DataLayer.productDetail(this.createDataLayerProduct(false, 'productDetails'));
        } else {
          console.warn('productDetails has been pushed already!');
        }
      }.bind(this);

      this.trackingPurchaseComplete = function () {

        if (this.isPurchaseCompleteTracked() == false) {
          this.isPurchaseCompleteTracked(true);
          this.save();

          // Push DataLayer purchaseComplete Event
          DataLayer.purchaseCompleted({
            totalPrice: this.totalPrice().toString(),
            id: this.couponId()
          }, this.createDataLayerProduct());
        } else {
          console.warn('purchaseCompleted has been pushed already!');
        }
      }.bind(this);

      // Functions - For extracting data:

      this.getRow = function (row) {
      // Notice that row param starts with index 1
        return this.rows()[row - 1];
      }.bind(this);

      this.getRows = function (minimumRowLength) {
        if (typeof minimumRowLength === 'undefined') {
          minimumRowLength = 1;
        }

        var rows = this.rows();

        return rows.filter(function (row) {
          return (row.state !== 'remove' && row.numbers.length > minimumRowLength);
        });
      }.bind(this);

      this.hasNumber = function (row, number) {
        row = this.getRow(row);

        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            return true;
          }
        }

        return false;
      }.bind(this);

      this.calculateWinnings = function (row) {
        row = this.getRow(row);

        var prizeGroup = KenoInfo.getPrizeGroups(row.numbers.length);
        var values = prizeGroup ? Object.keys(prizeGroup).map(function (key) { return prizeGroup[key]; }) : [0];
        var multiplier = Math.max.apply(null, values);

        return multiplier * this.stakePerRow();
      }.bind(this);

      this.drawDateHtml = function (delimiter) {
        delimiter = delimiter || ' - ';

        if (!KenoInfo.data().openDraw) {
          return '';
        }

        var closingTime = KenoInfo.data().openDraw.closingTime;
        var draws = this.numberOfDraws();

        var date = new Date(closingTime);

        var result = KenoUtils.formatISO8601(date.toISOString(), { includeTime: false });

        if (draws > 1) {
          date.setDate(date.getDate() + (draws - 1));
          result = '<span class="multiple-draws">' + result + delimiter + KenoUtils.formatISO8601(date.toISOString(), { includeTime: false }) + '</span>';
        }

        return result;
      }.bind(this);

      this.drawDateHtmlShort = function () {
        var dates = this.drawDates();
        var firstDate = dates[0];
        var lastDate = dates[dates.length - 1];

        // if there's more than one date, output the range. otherwise output the first date.
        if (dates.length > 1) {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' - ' + lastDate.draw.getDate() + '/' + (lastDate.draw.getMonth() + 1) + ' ' + lastDate.draw.getFullYear();
        } else {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' ' + firstDate.draw.getFullYear();
        }
      }.bind(this);

      this.drawDates = function () {
        var drawDates = [];
        var openDraw = KenoInfo.data().openDraw;
        var draws = this.numberOfDraws();

        if (!openDraw || !openDraw.closingTime) {
          return drawDates;
        }

        var closingTime = openDraw.closingTime;

        while (draws--) {
          var futureDate = new Date(closingTime);
          futureDate.setDate(futureDate.getDate() + draws);
          drawDates.push({
            draw: futureDate,
            hasJoker: false
          });
        }

        drawDates.sort(function (a, b) {
          return a.draw.getTime() - b.draw.getTime();
        });

        return drawDates;
      }.bind(this);

      this.getRowsSummary = function () { // Required by PurchaseBar
        var summary = [];
        var rows = this.getRows();

        for (var i = 0; i < rows.length; i++) {
          var row = rows[i].numbers.map(function (numbers) { return numbers.number; });

          summary.push(row);
        }

        return summary;
      }.bind(this);

      this.getRowAmount = function () {
        var numberOfDraws = this.numberOfDraws();
        var playType = this.playType();

        if (playType === 'KenoSystem') {
          return this.getSystemCombinationAmount();
        }

        return playType === 'Lightning' && numberOfDraws > 0 ? this.rowsToGenerate() : this.getRows().length;
      }.bind(this);

      this.totalPrice = function () {
        var numberOfDraws = this.numberOfDraws();
        var playType = this.playType();
        var stakePerRow = this.stakePerRow();

        if (playType === 'KenoSystem') {
          return stakePerRow * this.getSystemCombinationAmount() * numberOfDraws;
        }

        var rowsCount = this.getRowAmount();
        var price = stakePerRow * rowsCount;

        if (numberOfDraws > 0) {
          price *= numberOfDraws;
        }

        return price;
      }.bind(this);

      this.trackPurchase = function () {
        var self = this;

        if (!self.purchaseTracked() && self.status() == 'completed') {
          window.DSAPI && DSAPI.ready(function () {
            self.purchaseTracked(true);
          });

          this.trackingPurchaseComplete();
        }
      }.bind(this);

      this.cancelUrl = function () {
        if (this.rebuyCouponId()) {
          // This is called when going from confirm page, back to completed games overview page.
          return this.startUrl();
        }

        // This is called when going from confirm page, back to game.
        return this.startUrl() + '?gameInstanceId=' + this.id();
      }.bind(this);

      this.copyFromOldCoupon = function () {
        var self = this;

        KenoApi.getCoupon(this.rebuyCouponId()).then(function (data) {
          self.numberOfDraws(!data.multiWagers ? 1 : data.multiWagers.length);
          self.playType((data.playType.substring(0, 4) === 'Keno' ? '' : 'Keno') + data.playType.charAt(0).toUpperCase() + data.playType.slice(1));
          self.systemLevel(data.systemLevel);

          // playType System fix:
          if (!self.playType() || self.playType() === 'KenoUnknown') {
            self.playType(self.systemLevel() ? 'KenoSystem' : 'KenoClassic');
          }

          data.games.forEach(function (game) {
            var rowNumbers = [];

            // Classic, Million, Small and Large:
            if (self.playType() === 'KenoClassic' || self.playType() === 'KenoMillion' || self.playType() === 'KenoSmall' || self.playType() === 'KenoLarge') {

              game.rows.forEach(function (row, index) {
                rowNumbers = { count: index, numbers: [], state: 'clean' };

                row.numbers.forEach(function (number) {
                  rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                });

                self.rows().push(rowNumbers);
              });

              self.stakePerRow(data.price / self.getRowsSummary().length);


            // System:
            } else if (self.playType() === 'KenoSystem') {
              rowNumbers = { count: 0, numbers: [], state: 'clean' };

              game.systemNumbers.numbers.forEach(function (number) {
                rowNumbers.numbers.push({ autogenerated: false, number: number.number });
              });

              self.rows().push(rowNumbers);

              self.stakePerRow(data.price / self.getSystemCombinationAmount());

            }

          });

          self.save();

          self.ready().resolve();
        }, function () {
          self.ready().reject('coupon-not-found');
        });
      }.bind(this);

      this.toJSON = function () {
        return {
          id: this.id(),
          state: this.state(),
          status: this.status(),
          playType: this.playType(),
          stakePerRow: this.stakePerRow(),
          numberOfDraws: this.numberOfDraws(),
          numberOfNumbersPerRow: this.numberOfNumbersPerRow(),
          rows: this.rows(),
          rowsToGenerate: this.rowsToGenerate(),
          systemLevel: this.systemLevel(),
          startUrl: this.startUrl(),
          purchaseTracked: this.purchaseTracked(),
          couponId: this.couponId(),
          isProductDetailsTracked: this.isProductDetailsTracked(),
          isAddToCartTracked: this.isAddToCartTracked(),
          isPurchaseCompleteTracked: this.isPurchaseCompleteTracked(),
          firstDepositInfo: this.firstDepositInfo(),
          plusSubscriptionJackpot: this.plusSubscriptionJackpot()
        };
      }.bind(this);

      // Init:
      this.init = function () {

        // Rebuy coupon:
        if (this.rebuyCouponId() && this.state() === 'new') {
          this.copyFromOldCoupon();

        // Regular coupon:
        } else {
          if (this.state() === 'new') {
            this.addRow();
          }

          this.ready().resolve();
        }

      }.bind(this);

    });

    KenoGame.setupGame = function (options) {
      var game = undefined;

      if (options.gameInstanceId) {
        game = KenoGame.get(options.gameInstanceId);

        if (!game || game.status() === 'completed' || (options.playType && game.playType() !== options.playType)) {
          game = undefined;
        }
      }

      if (!game) {
        game = KenoGame.construct(options, options.state || 'new');
      }

      return game;
    };


    // Public functions:
    return KenoGame;

  });
