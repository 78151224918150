
defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var Overlay = function (template) {

      // Variables:
      this.$container = m.prop();
      this.$wrapper = m.prop(document.getElementById('wrapper'));

      // Functions:
      this.clear = function (action) {
        var self = this;

        return function () {
          var $container = self.$container();
          var $gameOverlay = $DS('.'.concat(template.class().split(' ').join('.')));

          var $wrapper = self.$wrapper();

          $gameOverlay.addClass('remove-overlay');

          $wrapper.className = $wrapper.className.replace('overlay-active', 'remove-overlay');

          $gameOverlay.unbind().on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
            if ($container && $container.parentNode) {
              $container.parentNode.removeChild($container);
            }

            $wrapper.className = $wrapper.className.replace('remove-overlay', '');

            if (typeof action === 'function') {
              action();
            }

            m.redraw();
          });
        };
      }.bind(this);

      this.render = function (confirm, dismiss) {
        var $overlay = document.createElement('div');
        var $gameOverlay = $DS('.' + template.class());

        $gameOverlay.unbind();

        var render = template.render(this.clear(confirm, 'confirm'), this.clear(dismiss, 'dismiss'));

        m.render($overlay, render);

        this.$container($overlay);
      }.bind(this);

      this.show = function (confirm, dismiss) {
        this.render(confirm, dismiss);

        $DS(this.$wrapper()).addClass('overlay-active');

        document.body.appendChild(this.$container());
      }.bind(this);

    };

    // Public functions:
    return Overlay;

  });
